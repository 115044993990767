<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            codeGroupCd="IBM_TASK_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ibmTaskTypeCd"
            label="개선구분"
            v-model="searchParam.ibmTaskTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            stepperGrpCd="IBM_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ibmStepCd"
            label="진행단계"
            v-model="searchParam.ibmStepCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 요청부서 -->
          <c-dept
            :plantCd="searchParam.plantCd"
            :isFirstValue="false"
            type="search"
            label="요청부서"
            name="improveRequestDeptCd"
            v-model="searchParam.improveRequestDeptCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 조치부서 -->
          <c-dept
            :plantCd="searchParam.plantCd"
            type="search"
            label="조치부서"
            name="actionDeptCd"
            v-model="searchParam.actionDeptCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-checkbox
            :isFlag="true"
            falseLabel="All"
            label="지연된 업무 확인"
            name="overFlag"
            v-model="searchParam.overFlag"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-datepicker
            :range="true"
            defaultStart="-2M"
            defaultEnd="2M"
            label="(기간)조치완료 요청일"
            name="actionCompleteRequestDates"
            v-model="searchParam.actionCompleteRequestDates"
          />
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        개선관리
        <div class="card-more">
          <q-btn-group outline >
              <c-btn label="등록" icon="add" @btnClicked="addEtcImpr" />
          </q-btn-group>
        </div>
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:top-right>
          </template>
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.ibmStepCd)]">
                    {{ props.row.ibmStepName }}
                  </div>
                  <div :class="['text-grid-etc', getColorStatus2(props.row.ibmTaskTypeCd)]">
                    {{ props.row.ibmTaskTypeName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.ibmTitle }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="txt-box-grid text-gray-box">
                    {{ props.row.improveRequestDeptName }}
                  </div>
                  <div class="text-grid-etc" :style="props.row.approvalStatusCd === 'ASC9999999' ? 'text-decoration: line-through' : ''">
                    {{ props.row.actionCompleteRequestDate }}
                    <q-badge v-if="props.row.completeDateOver > 0
                      && props.row.ibmStepCd !== 'IS00000001' && props.row.approvalStatusCd !== 'ASC9999999'" color="negative" label="지연" class="blinking" />
                  </div>
                  <div v-if="props.row.approvalStatusCd === 'ASC9999999'">👉 ~ {{props.row.targetDate}}</div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="bg-grey-2 text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinFooterSearch from '@/js/mixin-footer-search-common'
export default {
  name: 'impr',
  mixins: [mixinFooterSearch],
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        actionCompleteRequestDates: [],
        ibmTaskTypeCd: null,
        stepCd: null,
        improveRequestDeptCd: '',
        actionDeptCd: '',
        actionFlag: 'D',
        overFlag: 'N',
      },
      grid: {
        columns: [
          // {
          //   name: 'plantName',
          //   field: 'plantName',
          //   label: '사업장',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: '업무',
            align: 'center',
            style: 'width:25%',
            sortable: true
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:45%',
            sortable: true,
            type: 'link',
          },
          // {
          //   name: 'ibmStepName',
          //   field: 'ibmStepName',
          //   label: '진행단계',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true
          // },
          // {
          //   name: 'improveRequestContents',
          //   field: 'improveRequestContents',
          //   label: '요청정보',
          //   align: 'center',
          //   style: 'width:200px',
          //   sortable: true,
          // },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료 요청일',
            align: 'center',
            style: 'width:30%',
            sortable: true,
          },
          // {
          //   name: 'actionDeptName',
          //   field: 'actionDeptName',
          //   label: '조치부서',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.list.url
      // code setting
      // list setting
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'IS00000001': // 요청중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'IS00000005': // 조치부서 접수중
          cls = 'txt-box-grid text-positive-box';
          break;
        case 'IS00000010': // 조치부서 조치중
          cls = 'txt-box-grid text-accent-box';
          break;
        case 'IS00000015': // 요청부서 검토중
          cls = 'txt-box-grid text-red-box';
          break;
        case 'IS00000020': // 요청부서 검토중
          cls = 'txt-box-grid text-orange-box';
          break;
      }
      return cls;
    },
    getColorStatus2() {
      return 'txt-box-grid text-gray-box';
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row) {
      if (!row || row.ibmClassCd == 'IC00000001') {
        this.popupOptions.title = '개선 상세'; // 개선 상세
        this.popupOptions.param = {
          sopImprovementId: row ? row.sopImprovementId : '',
          ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT0000180',
          ibmTaskUnderTypeCd: row ? row.ibmTaskUnderTypeCd : 'ITTU000240',
        };
        this.popupOptions.target = () => import(`${'./imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시조치 상세';
        this.popupOptions.param = {
          sopImprovementId: row ? row.sopImprovementId : '',
          ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
          ibmTaskUnderTypeCd: row ? row.ibmTaskUnderTypeCd : 'ITTU000230',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
